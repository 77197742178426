'use client'

import { useEffect } from 'react'
import { useRecordError } from 'aws-rum-react'

import Error from 'next/error'

function LivError({
  error,
}: Readonly<{
  error: Error & { digest?: string; code?: number }
}>): React.ReactElement {
  const recordError = useRecordError()

  useEffect(() => {
    recordError(error)
  }, [error, recordError])
  return (
    <Error
      statusCode={error.code ?? 0}
      title={
        error.digest ??
        'Un error inesperado ha ocurrido! Nuestro equipo de tecnología ha sido informado'
      }
    />
  )
}

export default LivError
